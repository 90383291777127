<template>
  <div>
    <div class="zone">
      <div class="header">
        <h1>Assises 2025 JCI Tournai</h1>
        <nav class="steps">
            <div
            v-for="(stepItem, index) in steps"
            :key="index"
            :class="['step', stepItem.status]"
          >
            <!-- Si l'étape est 'done' ou 'active', rendre un router-link -->
            <router-link
              v-if="stepItem.status === 'done' || stepItem.status === 'active'"
              :to="stepItem.path"
              class="link"
            >
              <i v-if="stepItem.status === 'done'" class="checked fa-solid fa-check"></i>
              <i class="icon" :class="stepItem.icon"></i>
              <span class="name">{{ stepItem.name }}</span>
            </router-link>
            <!-- Si l'étape est 'pending', rendre un span non cliquable -->
            <span v-else class="link">
              <i :class="stepItem.icon"></i>
              <span class="name">{{ stepItem.name }}</span>
            </span>
          </div>
        </nav>
      </div>
      <div class="form">
        <router-view :formData="formData" :events="events" :promoInfos="promoInfos" :updateDatasUser="updateDatasUser"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        tarifs: "late",
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        section: '',
        firsttimer: false,
        address: '',
        postalCode: '',
        city: '',
        invoice: false,
        invoiceName: '',
        invoiceTVA: '',
        invoiceAddress: '',
        invoicePostalCode: '',
        invoiceCity: '',
        international: false,
        promo: '',
        selectedEvents: []
      },
      steps: [
        { name: 'Programme', path: '/', icon: 'fas fa-calendar-alt', status: '' },
        { name: 'Coordonnées', path: '/coordonnees', icon: 'fas fa-address-book', status: '' },
        { name: 'Paiement', path: '/paiement', icon: 'fas fa-credit-card', status: '' },
        { name: 'Confirmation', path: '/confirmation', icon: 'fas fa-check-circle', status: '' },
      ],
      // promoInfos: {
      //   "id":1,
      //   "name":"Ath Pays Vert",
      //   "code":"ath",
      //   "expiration_date":"2024-11-07",
      //   "expired": false
      // },
      promoInfos: false,
      events: {
        "complet": {
          "name": "Complet (vendredi + samedi)",
          "tournai": 120,
          "earlybird": 145,
          "regular": 175,
          "late": 205,
          "lock": ["samedijournee","samedicomplet","gala"]
        },
        "samedijournee": {
          "name": "Samedi (journée)",
          "tournai": 0,
          "earlybird": 45,
          "regular": 60,
          "late": 70,
          "lock": ["complet","samedicomplet"]
        },
        "samedicomplet": {
          "name": "Samedi (journée + gala)",
          "tournai": 120,
          "earlybird": 95,
          "regular": 145,
          "late": 170,
          "lock": ["complet","samedijournee","gala"]
        },
        "business": {
          "name": "Business/Senator Lunch samedi",
          "tournai": 40,
          "earlybird": 40,
          "regular": 40,
          "late": 40,
          "lock": []
        },
        "gala": {
          "name": "Samedi (gala)",
          "tournai": 90,
          "earlybird": 80,
          "regular": 90,
          "late": 110,
          "lock": ["complet","samedicomplet"]
        },
        "brunch": {
          "name": "Brunch dimanche",
          "tournai": 20,
          "earlybird": 20,
          "regular": 20,
          "late": 25,
          "lock": []
        },
      }
    };
  },
  created() {
    this.updateStepStatuses();
    const storedFormData = sessionStorage.getItem('formData');
    if (storedFormData) {
      this.formData = JSON.parse(storedFormData);
    }
    // Récupérer le code promo dans l'url "?code="
    const urlParams = new URLSearchParams(window.location.search);
    const promoCode = urlParams.get('code');
    if (promoCode) {
      if(promoCode=="tournaionly"){
        this.formData.tarifs = "tournai";
        this.formData.promo = "";
      }else if(promoCode=="earlybird"){
        this.formData.tarifs = "earlybird";
        this.formData.promo = "";
      }else{
        this.formData.promo = promoCode;
        this.updateDatasUser(this.formData);
      }
    }
    if(this.formData.promo) this.checkPromoCode(this.formData.promo);
  },
  watch: {
    $route() {
      this.updateStepStatuses();
    },
  },
  methods: {
    updateStepStatuses() {
      const routePath = this.$route.path;
      const currentStepIndex = this.steps.findIndex(step => step.path === routePath);

      this.steps.forEach((step, index) => {
        if (index < currentStepIndex) {
          step.status = 'done';
        } else if (index === currentStepIndex) {
          step.status = 'active';
        } else {
          step.status = 'pending';
        }
      });
    },
    updateDatasUser(data) {
      this.formData = data;
      sessionStorage.setItem('formData', JSON.stringify(this.formData));
    },
    checkPromoCode(promoCode) {
      fetch(`/api/check-promo-code.php?code=${promoCode}`)
      .then(response => response.json())
      .then(data => {
        // {"id":1,"0":1,"name":"Ath Pays Vert","1":"Ath Pays Vert","code":"ath","2":"ath","expiration_date":"2024-11-07","3":"2024-11-07","created_at":"2024-11-05 14:09:45","4":"2024-11-05 14:09:45"}
        if (data.id) {
          const expirationDate = new Date(data.expiration_date);
          const currentDate = new Date();
          if (currentDate > expirationDate) {
            data.expired = true;
          } else {
            data.expired = false;
          }
        } else {
          data = false;
        }
        this.promoInfos = data;
        if(this.promoInfos) this.updateTimeLeft();
      })
    },
    updateTimeLeft() {
      const expirationDate = new Date(this.promoInfos.expiration_date);
      const currentDate = new Date();
      const timeDifference = expirationDate - currentDate;
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
      this.promoInfos.timeLeft = `${('0'+(days*24 + hours)).slice(-2)}:${('0'+minutes).slice(-2)}:${('0'+seconds).slice(-2)}`;
      if(timeDifference <= 0) this.promoInfos.expired = true;
      else setTimeout(() => this.updateTimeLeft(), 1000);
    }
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
html, body{
  background: #58A681 url('../public/background.png') no-repeat center center fixed;
  background-size: cover;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #242426;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
}

h1{
  text-align: center;
}
.zone{
  background: white;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: rgba(0,0,0,0.3) 0px 0px 40px;
}
.header{
  background: #242426;
  color: white;
  padding: 20px 20px 0px 20px;
  margin-bottom: 20px;
}
.form{
  padding: 20px;
  
  h2{
    margin-top: 0;
  }
}
.steps {
  display: flex;
  justify-content: center;
  gap: 20px;
  position: relative;
  top: 20px;

  .step{
    text-decoration: none;
    font-weight: bold;
    position: relative;

    .link{
      background: #c1c1c1;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: relative;
      z-index: 1;
      text-decoration: none;

      &.router-link-active{
        background: #F2B705;
        cursor: default;
      }
    }
    &.done .link{
      background: #58A681;
    }
    &.done, &.active{
      &:after{
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        height: 4px;
        width: 500px;
        transform: translateY(-2px);
        background: #F2B705;
      }
    }
    
    .icon{
      display: block;
      font-size: 1.2em;
    }
    .checked{
      position: absolute;
      left: 75%;
      top: -12px;
      font-size: 1.2em;
      color: #F2B705;
      pointer-events: none;
    }
    .name{
      display: block;
      font-size: 13px;
      position: absolute;
      bottom: 100%;
      margin-bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      background: #F2B705;
      padding: 5px 10px;
      border-radius: 5px;
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
      pointer-events: none;

      &:after{
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #F2B705;
      }
    }
    &:hover{
      .name{
        opacity: 1 ;
      }
    }
  }

}
.actions{
  display: flex;
  gap: 10px;

  .nextstep, .prevstep{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    background: #58A681;
    color: white;
    padding: 10px 20px;
    border: 0px none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
    font-size: 16px;
    flex-grow: 1;
    text-decoration: none;
    box-sizing: border-box;

    &:hover{
      background: #F2B705;
    }
    &.freeze{
      background:#c1c1c1;
      cursor: not-allowed;

      &:hover{
        background: #c1c1c1;
      }
    }
  }
}
</style>
